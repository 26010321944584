import { properties } from "../properties";
import api from "../services/api";

function fetchEmailTemplateList() {
  return api.get(`${properties.adminService}/email-templates/templates`);
}

function fetchDefaultEmailTemplate(templateId) {
  return api.get(`${properties.adminService}/email-templates/${templateId}`);
}

function fetchCustomEmailTemplate(templateId) {
  return api.get(`${properties.adminService}/custom-email-templates/${templateId}`);
}

function updateCustomEmailTemplate(templateCategoryId, data) {
  return api.put(
    `${properties.adminService}/custom-email-templates/${templateCategoryId}/template`,
    data
  );
}

function updateIsCustomTemplateUsed(templateCategoryId, isCustomTemplateUsed) {
  return api.put(
    `${properties.adminService}/custom-email-templates/${templateCategoryId}/is-custom-template-used`,
    isCustomTemplateUsed,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
}

function fetchIsCustomEmailUsed(templateId) {
  return api.get(
    `${properties.adminService}/custom-email-templates/${templateId}/is-custom-template-used`
  );
}

function sendTestEmailRequest(data) {
  return api.post(
    `${properties.adminService}/custom-email-templates/send-test-email`,
    data
  );
}

export {
  fetchEmailTemplateList,
  fetchDefaultEmailTemplate,
  fetchCustomEmailTemplate,
  updateCustomEmailTemplate,
  updateIsCustomTemplateUsed,
  sendTestEmailRequest,
  fetchIsCustomEmailUsed
};
