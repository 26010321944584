import React, { useState, useEffect } from "react";
import { IconButton, Toolbar, Divider } from "@mui/material";
import FormShimmering from "../utility/FormShimmer";
import CloseIcon from "@mui/icons-material/Close";
import TableComponent from "../utility/Table";
import {
  getDeviceApprovalRequests,
  updateApprovalStatus as updateApprovalStatusAPI
} from "../../API/devices";
import { DEVICE_APPROVAL_STATUS, MESSAGES } from "../../utils/Constants";

function DeviceApprovalRequests({ open, setOpen, setFeedback }) {
  const [loading, setLoading] = useState(true);
  const [approvalRequests, setApprovalRequests] = useState(null);
  const [selectedRecordId, setSelectedRecordId] = useState([]);
  const [approvalRequestMap, setApprovalRequestMap] = useState({});

  useEffect(() => {
    setLoading(true);
    getDeviceApprovalRequests()
      .then(({ data }) => {
        setApprovalRequests(data);
        const approvalRequestMapTemp = {};

        data.map((item) => {
          approvalRequestMapTemp[item.id] = { ...item };
        });

        setApprovalRequestMap(approvalRequestMapTemp);

        setLoading(false);
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: MESSAGES.GENERIC_EXCEPTION_MESSAGE,
          state: true
        });
      });
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  function onClickApprove(deviceId) {
    updateApprovalStatus(deviceId, DEVICE_APPROVAL_STATUS.APPROVED);
  }

  function onClickDeny(deviceId) {
    updateApprovalStatus(deviceId, DEVICE_APPROVAL_STATUS.DENIED);
  }

  function updateApprovalStatus(deviceId, approvalStatus) {
    updateApprovalStatusAPI(deviceId, approvalStatus)
      .then(() => {
        setApprovalRequests((approvalRequests) => {
          return approvalRequests.map((approvalRequest) => {
            if (approvalRequest["id"] === deviceId)
              approvalRequest["approvalStatus"] = approvalStatus;
            return approvalRequest;
          });
        });
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: MESSAGES.GENERIC_EXCEPTION_MESSAGE,
          state: true
        });
      });
  }

  const getActionList = (id) => {
    let actions = [];
    if (
      approvalRequestMap[id] &&
      approvalRequestMap[id].approvalStatus !==
        DEVICE_APPROVAL_STATUS.APPROVED &&
      approvalRequestMap[id].approvalStatus !== DEVICE_APPROVAL_STATUS.DENIED
    ) {
      actions = [
        {
          label: "Approve",
          onClickHandler: onClickApprove,
          hasParameter: true
        },
        {
          label: "Deny",
          onClickHandler: onClickDeny,
          hasParameter: true
        }
      ];
    }
    return actions;
  };

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
      style={{
        width: "60%",
        height: "100%"
      }}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      {loading ? (
        <FormShimmering />
      ) : (
        <div className="drawer-form-container">
          <h5>Device Approval Requests</h5>
          <Divider sx={{ opacity: 1 }} />
          <TableComponent
            headings={[
              "id",
              "deviceName",
              "operatingSystem",
              "agentVersion",
              "approvalStatus"
            ]}
            options={{ hideIds: true, maxChips: 2 }}
            data={approvalRequests}
            styles={{
              status: "chip",
              deviceName: "button",
              table: { maxHeight: 525 }
            }}
            actions={[]}
            getActionListHandler={getActionList}
            columnWidths={{
              operatingSystem: 250,
              displayName: 130,
              deviceName: 130
            }}
            customColumnNames={{
              id: "Device ID"
            }}
          />
        </div>
      )}
    </div>
  );
}

export default DeviceApprovalRequests;
