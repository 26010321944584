import { properties } from "../properties";
import api from "../services/api";

function agentPrepare(packageRequestJson) {
  return api.post(
    `${properties.adminService}/package/agent`,
    packageRequestJson,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
}

function agentDownload() {
  return api.get(`${properties.adminService}/package/agent`, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function agentStatus(packageRequestJson) {
  return api.get(
    `${properties.adminService}/package/agent-status`,
    packageRequestJson,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
}

export { 
  agentDownload, 
  agentStatus, 
  agentPrepare 
};
