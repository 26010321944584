import React from "react";
import ReportList from "./ReportList";

export default function EthernetReportList() {
  const headings = [
    "id",
    "deviceName",
    "incidentDttm",
    "Shared username",
    "User",
    "Auth type",
    // "policyName",
    // "Ethernet Connection Name",
    // "risk",
  ];
  const subheading = "";
  return <ReportList headings={headings} destinationId={7} subheading={subheading} />;
}
