import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import {
  agentDownload,
  agentStatus,
  agentPrepare
} from "../../API/agentDownload";
import { DEVICE_TYPE, MESSAGES } from "../../utils/Constants";
import { AGENT_MESSAGES } from "../../utils/Messages";

export default function AgentDownloadButton({ setFeedback }) {
  const [agentExists, setAgentExists] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkAgentAvailability = () => {
    setLoading(true);
    agentStatus()
      .then((response) => {
        setAgentExists(response.data);
      })
      .catch((e) => {
        setFeedback({
          severity: "error",
          message: MESSAGES.GENERIC_EXCEPTION_MESSAGE,
          state: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDownloadHandler = () => {
    setLoading(true);

    setFeedback({
      severity: "info",
      message: AGENT_MESSAGES.AGENT_DOWNLOAD_STARTED,
      state: true
    });

    agentDownload({ responseType: "blob" })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/octet-stream"
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "modlp.msi");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setFeedback({
          severity: "success",
          message: AGENT_MESSAGES.AGENT_DOWNLOAD_COMPLETE,
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: AGENT_MESSAGES.AGENT_DOWNLOAD_FAILED,
          state: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPrepareHandler = () => {
    setLoading(true);

    const packageRequestJson = {
      agentVersion: "1.0",
      needUpgrade: false,
      fullAgentParcel: true,
      deviceType: DEVICE_TYPE.COMPANY_OWNED
    };

    agentPrepare(packageRequestJson)
      .then(() => {
        setFeedback({
          severity: "success",
          message: AGENT_MESSAGES.AGENT_DOWNLOAD_SUCCESS,
          state: true
        });
      })
      .catch((e) => {
        setFeedback({
          severity: "error",
          message: MESSAGES.GENERIC_EXCEPTION_MESSAGE,
          state: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    checkAgentAvailability();
  }, []);

  const handleClick = () => {
    agentExists ? onDownloadHandler() : onPrepareHandler();
  };

  const buttonText =
    agentExists === null
      ? "Loading..."
      : agentExists
      ? "Download Module"
      : "Prepare Module";

  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      className="btn-primary-main-add"
      disabled={loading}
    >
      <Box className="btns-with-icon">
        <Typography className="btns-with-icon-text">{buttonText}</Typography>
      </Box>
    </Button>
  );
}
