import React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { authenticateUser } from "../../services/index";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Logo from "../assets/logo.svg";
import Button from "@mui/material/Button";
import { changePassword } from "../../API/userCredentials";
import { Box, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function ChangePassword({ setFeedback }) {
  const navigate = useNavigate();
  const { userId, token } = useParams();

  window.history.replaceState({}, document.title);

  function submitForm(values) {
    changePassword({
      token: token,
      newPassword: values.password,
      userId: userId
    })
      .then(() => {
        setFeedback({
          message: "Password Reset Successfully",
          severity: "success",
          state: true
        });
        navigate("/login");
      })
      .catch(function (error) {
        setFeedback({
          message: error.response.data,
          severity: "error",
          state: true
        });
      });
  }

  const validate = Yup.object({
    password: Yup.string()
      .min(6, "Password must be at least 6 charaters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Confirm Password is required")
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      submitForm(values, resetForm);
    }
  });

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);

  const togglePassword = () => {
    setIsPasswordShown((isPasswordShown) => !isPasswordShown);
  };

  const toggleConfirmPassword = () => {
    setIsConfirmPasswordShown(
      (isConfirmPasswordShown) => !isConfirmPasswordShown
    );
  };

  return (
    <div className="auth-form">
      <img className="logo" src={Logo} />
      <br />
      <br />
      <h1 className="auth-heading">Reset Password</h1>
      <p className="auth-subheading">Secure your account with new password</p>
      <br />
      <form className="form" onSubmit={formik.handleSubmit}>
        <TextField
          className="input-field"
          fullWidth
          required
          placeholder="New Password"
          name="password"
          type={isPasswordShown ? "text" : "password"}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => togglePassword()} edge="end">
                  {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon sx={{ height: "24px", width: "24px" }} />
              </InputAdornment>
            ),
            style: {
              fontSize: "17px"
            }
          }}
          InputLabelProps={{ style: { color: "#848484", fontSize: "15px" } }}
        />
        <br />
        <TextField
          className="input-field"
          fullWidth
          required
          placeholder="Confirm Password"
          name="confirmPassword"
          type={isConfirmPasswordShown ? "text" : "password"}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => toggleConfirmPassword()} edge="end">
                  {isConfirmPasswordShown ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon sx={{ height: "24px", width: "24px" }} />
              </InputAdornment>
            ),
            style: {
              fontSize: "17px"
            }
          }}
          InputLabelProps={{ style: { color: "#848484", fontSize: "15px" } }}
        />
        <br />
        <Button
          className="submit-button"
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          RESET PASSWORD
        </Button>
      </form>
      <br />
      <div style={{ alignItems: "flex-start" }} className="form">
        <Button onClick={() => navigate("/login")}>
          <Box className="btns-with-icon">
            <KeyboardBackspaceIcon sx={{ fontSize: "18px" }} />
            <Typography className="btns-with-icon-text">
              Back to Login
            </Typography>
          </Box>
        </Button>
      </div>
    </div>
  );
}

export default ChangePassword;
