import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EmailTemplateEditor from "./EmailTemplateEditor";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Radio from "@mui/material/Radio";
import "./email-templates.css";
import { useSelector } from "react-redux";
import { Button, InputLabel, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import SendIcon from '@mui/icons-material/Send';

import {
  fetchDefaultEmailTemplate,
  updateIsCustomTemplateUsed,
  updateCustomEmailTemplate,
  fetchIsCustomEmailUsed,
  fetchCustomEmailTemplate,
  sendTestEmailRequest
} from "../../API/emailTemplates";
import PageTitle from "../utility/PageTitle";
import { EMAIL_TEMPLATE_NAMES, MESSAGES } from "../../utils/Constants";

function EmailTemplateEditorPage({ setFeedback }) {
  const user = useSelector((state) => state.auth.currentUser);
  const location = useLocation();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const [template, setTemplate] = useState("");
  const [isCustomTemplateUsed, setIsCustomTemplateUsed] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const templateCategoryId = queryParams.get("id");
  const templateCategoryName = queryParams.get("name");
  const [subject, setSubject] = useState(EMAIL_TEMPLATE_NAMES[templateCategoryName]);

  useEffect(() => {
    fetchIsCustomEmailUsed(templateCategoryId)
      .then(({ data }) => {
        setIsCustomTemplateUsed(data);
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        });
      });
  },[]);

  useEffect(() => {
    if (isCustomTemplateUsed) {
      fetchCustomEmailTemplate(templateCategoryId)
        .then(({ data }) => {
          setTemplate(data);
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: MESSAGES.GENERIC_EXCEPTION_MESSAGE,
            state: true
          });
        });
    } else {
      fetchDefaultEmailTemplate(templateCategoryId)
        .then(({ data }) => {
          setTemplate(data);
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: MESSAGES.GENERIC_EXCEPTION_MESSAGE,
            state: true
          });
        });
    }
  }, [isCustomTemplateUsed]);

  const handleTemplateOptionChange = (event) => {
    const data = event.target.value == "custom-template" ? true : false;
    updateIsCustomTemplateUsed(templateCategoryId, data)
      .then(() => {
        setIsCustomTemplateUsed(data);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Couldn't select template. Something went wrong.",
          state: true
        });
      });
  };

  const saveCustomTemplate = () => {
    if (editorRef.current) {
      const data = {
        template: editorRef.current.getContent()
      };
      updateCustomEmailTemplate(templateCategoryId, data)
        .then(() => {
          setFeedback({
            severity: "success",
            message: "Template saved successfully.",
            state: true
          });
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "Something went wrong",
            state: true
          });
        });
    }
  };

  const goBack = () => {
    navigate("/customization/email-templates");
  };

  const sendTestEmail = () => {
    const data = {
      emailTemplate: editorRef.current.getContent(),
      subject: subject
    };
    sendTestEmailRequest(data)
      .then(() => {
        setFeedback({
          severity: "success",
          message: "Test email sent successfully.",
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Failed to send test email.",
          state: true
        });
      });
  };

  return (
    <>
      <PageTitle />
      <div className="custom-header">
        <div className="back-button" onClick={goBack}>
          <IconButton aria-label="delete" className="icon-float-left">
            <ArrowBackIcon fontSize="medium" />
          </IconButton>
          <span>Back</span>
        </div>
      </div>
      <h6>Configure {EMAIL_TEMPLATE_NAMES[templateCategoryName]} Template</h6>
      <br />
      <div className="custom-radio-group">
        <Radio
          checked={!isCustomTemplateUsed}
          onChange={handleTemplateOptionChange}
          value="default-template"
          name="select-template-buttons"
          inputProps={{ "aria-label": "A" }}
          id="default-template"
        />
        <label htmlFor="default-template">Use Default Template</label>
        &emsp;
        <Radio
          checked={isCustomTemplateUsed}
          onChange={handleTemplateOptionChange}
          value="custom-template"
          name="select-template-buttons"
          inputProps={{ "aria-label": "B" }}
          id="custom-template"
        />
        <label htmlFor="custom-template">Use Customized Template</label>
      </div>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" alignItems="center" gap={2}>
          <InputLabel
            htmlFor="fromField"
            className="form-input-label"
            sx={{ flex: '0 0 100px' }}
          >
            From :
          </InputLabel>
          <TextField
            size="small"
            name="fromField"
            value="dlp@xecurify.com"
            disabled
            sx={{ flexBasis: '50%' }}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <InputLabel
            htmlFor="subjectField"
            className="form-input-label"
            sx={{ flex: '0 0 100px' }}
          >
            Subject :
          </InputLabel>
          <TextField
            size="small"
            name="subjectField"
            value={subject}
            disabled
            sx={{ flexBasis: '50%' }}
          />
        </Box>
      </Box>
      <br />
      <EmailTemplateEditor
        prevContent={template}
        editorRef={editorRef}
        isCustomTemplateUsed={isCustomTemplateUsed}
      />
      <br />
      <div className="bottom" style={{ textAlign: "left" }}>
        <br />
        <div>
          <span>To: {user.email}</span>
          <Button
            sx={{ ml: 2 }}
            onClick={sendTestEmail}
            variant="contained" endIcon={<SendIcon />}
          >
            Send test Email
          </Button>
        </div>
        <br />
        {isCustomTemplateUsed && (
          <>
            <button
              className="btn btn-primary"
              type="button"
              onClick={saveCustomTemplate}
            >
              Save
            </button>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={goBack}
              style={{ marginLeft: 10 }}
            >
              Cancel
            </button>
          </>
        )}
      </div>
      <br/>
    </>
  );
}

export default EmailTemplateEditorPage;
