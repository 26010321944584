import {
  Checkbox,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { capitalizefirstLetter } from "../../utils/Helpers";
import TableShimmering from "./TableShimmering";
import "./table.css";
import Actions from "./Actions";
import StyledSwitch from "./StyledSwitch";
import RowDetailsComponent from "./RowDetailsComponent";
import RowExpandButton from "./RowExpandButton";
import NoRecordsFound from "../assets/Illustrator/NoRecordsFound";
import CustomBadge from "./CustomBadge";
import { Link } from "react-router-dom";
import FaviconFromDomain from "../assets/icons/FaviconFromDomain";
import CustomTooltip from "../../utils/Tooltip";

function TableComponent({
  headings,
  data,
  route,
  options,
  styles,
  customColumnNames = {},
  actions,
  getActionListHandler,
  multiselect,
  toggleAction,
  collapsibleDetails,
  chipOnClickHandler,
  columnWidths,
  buttonActions,
  chipsStyles,
  customColumnStyles,
  tootltipMessages
}) {
  const navigate = useNavigate();

  function formatHeading(heading) {
    let i = 0;
    let j = 0;
    let formattedHeading = [];
    while (j < heading.length) {
      if (heading[j].toUpperCase() === heading[j]) {
        if (heading.slice(i, j) == "I" && heading[j] == "D") {
          formattedHeading.push(heading.slice(i, ++j));
        } else {
          formattedHeading.push(capitalizefirstLetter(heading.slice(i, j)));
        }
        i = j;
      }
      j += 1;
    }
    formattedHeading.push(capitalizefirstLetter(heading.slice(i, j)));
    return formattedHeading.join(" ");
  }

  const handleSelect = (id) => {
    if (multiselect?.selectedRecordId.includes(id)) {
      multiselect?.setSelectedRecordId(
        multiselect?.selectedRecordId.filter((recordId) => recordId !== id)
      );
    } else {
      multiselect?.setSelectedRecordId([...multiselect?.selectedRecordId, id]);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      multiselect?.setSelectedRecordId(data.map((record) => record.id));
    } else {
      multiselect?.setSelectedRecordId([]);
    }
  };
  return (
    <>
      {data === null ? (
        <TableShimmering />
      ) : (
        <>
          <TableContainer
            component={Paper}
            style={{ marginTop: "10px" }}
            sx={{
              maxHeight: styles?.table?.maxHeight,
              maxWidth:
                styles?.table?.maxWidth !== undefined
                  ? styles.table.maxWidth
                  : "100vw",
              boxShadow: "unset"
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {multiselect !== undefined && data.length !== 0 ? (
                    <TableCell sx={{ width: 10 }} key={"muliselect"}>
                      <Checkbox
                        checked={
                          multiselect?.selectedRecordId.length === data.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                  ) : null}
                  {collapsibleDetails !== undefined && data.length !== 0 ? (
                    <RowExpandButton
                      openRows={collapsibleDetails.openRows}
                      setOpenRows={collapsibleDetails.setOpenRows}
                      data={data}
                    />
                  ) : null}
                  {headings.map((heading, index) =>
                    index === 0 && options?.hideIds ? null : (
                      <TableCell
                        key={heading}
                        sx={{
                          minWidth:
                            columnWidths?.[heading] !== undefined
                              ? columnWidths[heading]
                              : 50
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {customColumnNames !== undefined && customColumnNames[heading] ? customColumnNames[heading] : formatHeading(heading)}
                          {tootltipMessages !== undefined && tootltipMessages[heading] && <CustomTooltip title={tootltipMessages[heading]} />}
                        </div>
                      </TableCell>
                    )
                  )}
                  {actions !== undefined ? (
                    <TableCell
                      style={{ width: 85, textAlign: "center" }}
                      key={"actions"}
                    >
                      Actions
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, i) => (
                  <React.Fragment key={i}>
                    <TableRow
                      key={row[headings[0]]}
                      onClick={(event) => {
                        if (
                          event.isPropagationStopped() ||
                          route === undefined
                        ) {
                          return;
                        }
                        navigate(`${route}/${row[headings[0]]}`);
                      }}
                    >
                      {multiselect !== undefined ? (
                        <TableCell sx={{ borderBottom: 0 }}>
                          <Checkbox
                            checked={multiselect?.selectedRecordId.includes(
                              row[headings[0]]
                            )}
                            onChange={() => handleSelect(row[headings[0]])}
                          />
                        </TableCell>
                      ) : null}
                      {collapsibleDetails !== undefined ? (
                        <RowExpandButton
                          openRows={collapsibleDetails.openRows}
                          setOpenRows={collapsibleDetails.setOpenRows}
                          recordId={row[headings[0]]}
                          data={data}
                        />
                      ) : null}
                      {headings.map((heading, index) =>
                        index === 0 && options?.hideIds ? null : heading ===
                          "IsEnabled" ? (
                          <TableCell sx={{ borderBottom: 0 }} key={index}>
                            <StyledSwitch
                              disabled={
                                options?.containsDefault && row["isDefault"]
                              }
                              checked={toggleAction.toggleStatusChecker(
                                row[headings[0]]
                              )}
                              onChange={(event) =>
                                toggleAction.onChangeHandler(
                                  row[headings[0]],
                                  event
                                )
                              }
                              inputProps={{
                                "aria-label": "toggle isEnabled"
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{ borderBottom: 0 }}
                            key={index}
                            style={customColumnStyles?.[heading]}
                          >
                            {Array.isArray(row[heading]) ? (
                              <CustomBadge
                                row={row}
                                heading={heading}
                                maxChips={options?.maxChips}
                                onClickHandler={chipOnClickHandler}
                              />
                            ) : (
                              (() => {
                                switch (styles?.[heading]) {
                                  case "chip":
                                    return (
                                      <Chip
                                        icon={
                                          chipsStyles
                                            ? chipsStyles[heading][row[heading]]
                                              ?.icon
                                            : null
                                        }
                                        label={row[heading]}
                                        style={
                                          chipsStyles
                                            ? chipsStyles[heading][row[heading]]
                                              ?.style
                                            : null
                                        }
                                        size="small"
                                      />
                                    );
                                  case "link":
                                    return (
                                      <Link to={`?id=${row[headings[0]]}`}>
                                        {row[heading]}
                                      </Link>
                                    );
                                  case "button":
                                    return (
                                      <Button
                                        color="primary"
                                        sx={{ minWidth: "unset" }}
                                        onClick={() =>
                                          buttonActions[heading].onClickHandler(
                                            row[headings[0]]
                                          )
                                        }
                                      >
                                        {row[heading]}
                                      </Button>
                                    );
                                  case "text-with-favicon":
                                    return (
                                      <>
                                        {options?.showFavicon ? (
                                          <FaviconFromDomain
                                            domain={row[heading]}
                                            style={{ marginRight: "10px" }}
                                          />
                                        ) : null}
                                        {row[heading]}
                                      </>
                                    );
                                  default:
                                    return row[heading] ? row[heading] : "--";
                                }
                              })()
                            )}
                            {options?.containsDefault &&
                              options?.defaultColumn === heading &&
                              row["isDefault"] ? (
                              <Chip
                                label={"Default"}
                                variant={"outlined"}
                                color="primary"
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "0.7125rem",
                                  height: "20px"
                                }}
                              />
                            ) : null}
                          </TableCell>
                        )
                      )}
                      {actions !== undefined ? (
                        <TableCell
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            borderBottom: 0
                          }}
                        >
                          <Actions
                            actionList={
                              options?.containsDefault && row["isDefault"]
                                ? []
                                : actions
                            }
                            recordId={row[headings[0]]}
                            getActionListHandler={getActionListHandler}
                            style={styles?.actionStyles}
                          />
                        </TableCell>
                      ) : null}
                    </TableRow>
                    {collapsibleDetails !== undefined ? (
                      <RowDetailsComponent
                        row={row}
                        openRows={collapsibleDetails.openRows}
                        numberOfColumns={headings.length}
                      />
                    ) : null}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {data.length === 0 ? (
            <div className="no-record-found-container">
              <div>
                <NoRecordsFound />
              </div>
              <div className="no-record-found-text">No Records Found</div>
              <br />
            </div>
          ) : null}
          <hr />
        </>
      )}
    </>
  );
}

export default TableComponent;
